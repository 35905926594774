import React from 'react';
import styled from 'styled-components';
import withPageEntry from '../../withPageEntry';
import * as Ant from 'antd';
import {ChevronsLeft} from '@styled-icons/boxicons-regular/ChevronsLeft';
import {ChevronsRight} from '@styled-icons/boxicons-regular/ChevronsRight';
import CourseItem from '../../Components/TwpaaCourseItem';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import {useOutlet} from 'reconnect.js';

const pageSize = 60;

const hoursTypeOptions = [
  {
    name: 'option_1',
    label: '會員在職進修獎勵時數',
  },
  {name: 'option_2', label: '專利師在職進修時數'},
  {name: 'option_3', label: '專利訴訟業務相關進修時數'},
  {name: 'option_4', label: '商標進修時數'},
];

function ProductList(props) {
  const [landing] = useOutlet('landing');
  const maxYear = new Date().getFullYear() + 1;
  const minYear = maxYear - 10;
  const [selectedYear, setSelectedYear] = React.useState(maxYear - 1);
  const [fetchResp, setFetchResp] = React.useState(null);
  const [paging, setPaging] = React.useState({offset: 0, limit: pageSize});
  const [selectedHoursType, setSelectedHoursType] = React.useState('');
  const [selectedSeriesType, setSelectedSeriesType] = React.useState('');

  const seriesTypeOptions = landing?.custom_layout?.course_series || [];
  const banner = landing?.custom_layout?.course_banner;

  const years = [];
  let y = maxYear;
  while (y >= minYear) {
    years.push(y--);
  }

  const fetchData = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      const extraQueries = {
        public: true,
        archived: {$ne: true},
        date: {$regex: selectedYear.toString()},
      };

      if (selectedHoursType) {
        extraQueries[`hours_catagory.${selectedHoursType}`] = true;
      }

      if (selectedSeriesType) {
        extraQueries.series = selectedSeriesType;
      }

      const {total, results} = await AppActions.twpaaFetchEventList({
        query: extraQueries,
        paging,
      });

      setFetchResp({
        total,
        results: results
          .filter((it) => !it.parent)
          .map((it) => {
            const childProduct = results.find((r) => r.parent === it.id);

            if (!childProduct) {
              return it;
            }

            return {
              ...it,
              _child_course: childProduct,
              _course_enroll_options: [
                {label: it.online ? '線上' : '實體', id: it.id},
                {
                  label: childProduct.online ? '線上' : '實體',
                  id: childProduct.id,
                },
              ],
            };
          }),
      });
    } catch (ex) {
      console.warn('REV_DBG', ex);
    }
    AppActions.setLoading(false);
  }, [paging, selectedYear, selectedHoursType, selectedSeriesType]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Wrapper
      bannerBg={
        banner ||
        'https://png.pngtree.com/thumb_back/fh260/background/20230408/pngtree-rainbow-curves-abstract-colorful-background-image_2164067.jpg'
      }>
      <main>
        <div className="banner" />

        <div id="twpaa-year-picker">
          <ChevronsLeft
            size={18}
            onClick={() => {
              setSelectedYear(Math.max(selectedYear - 1, minYear));
            }}
          />
          <div style={{flex: 1}} />
          <Ant.Select
            value={selectedYear}
            onChange={setSelectedYear}
            style={{border: 'none'}}>
            {years.map((y) => {
              return <Ant.Select.Option value={y}>{y}</Ant.Select.Option>;
            })}
          </Ant.Select>
          <div style={{flex: 1}} />
          <ChevronsRight
            size={18}
            onClick={() => {
              setSelectedYear(Math.min(selectedYear + 1, maxYear));
            }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 10,
            gap: 10,
          }}>
          <Ant.Select
            value={selectedSeriesType}
            onChange={setSelectedSeriesType}
            style={{width: 200}}>
            {[
              {
                name: '',
                label: '不指定系列',
              },
              ...seriesTypeOptions,
            ].map((it) => {
              return (
                <Ant.Select.Option value={it.name}>
                  {it.label || it.name}
                </Ant.Select.Option>
              );
            })}
          </Ant.Select>

          <Ant.Select
            value={selectedHoursType}
            onChange={setSelectedHoursType}
            style={{width: 200}}>
            {[
              {
                name: '',
                label: '不指定時數類型',
              },
              ...hoursTypeOptions,
            ].map((it) => {
              return (
                <Ant.Select.Option value={it.name}>
                  {it.label}
                </Ant.Select.Option>
              );
            })}
          </Ant.Select>
        </div>

        <div className="course-grid">
          {(fetchResp?.results || []).map((it) => {
            return (
              <CourseItem
                key={it.id}
                course={it}
                onClick={() => {
                  AppActions.navigate(`/product/?id=${it.id}`);
                }}
              />
            );
          })}
        </div>

        <div style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>
          <Ant.Pagination
            size="small"
            total={fetchResp?.total || 0}
            showSizeChanger={false}
            current={paging.offset / pageSize + 1}
            pageSize={pageSize}
            onChange={(page, pageSize) => {
              if (pageSize) {
                setPaging({
                  offset: (page - 1) * pageSize,
                  limit: pageSize,
                });
              }
            }}
          />
        </div>
      </main>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);
  min-height: var(--contentMinHeight);

  & > main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
  }

  main > .banner {
    border-radius: 16px;
    width: 100%;
    height: 300px;
    background-color: #eee;
    overflow: hidden;
    background: url(${(props) => props.bannerBg}) center center / cover;
  }

  #twpaa-year-picker {
    margin-top: 40px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;

    .ant-select-selector {
      border: none;
      font-size: 20px;
      color: #043186;
    }
  }

  .course-grid {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
`;

export default withPageEntry(ProductList);
